import { useQuery } from '@apollo/react-hooks';
import { useMemo } from 'react';

import type { StaticContext } from '@atlassian/forge-conditions';

import { useSessionData } from '@confluence/session-data';
import { getIsProductAdminFromQuery, getIsSiteAdminFromQuery } from '@confluence/current-user';
import { OperationsQuery } from '@confluence/site-operations';

import { forgeGlobalExtensions } from '../ForgeModuleType';
import type { ForgeModules } from '../ForgeModules';

import { UserPrivilegeQuery } from './UserPrivilegeQuery.graphql';
import { DisplayConditionsSpaceAdminQuery } from './DisplayConditionsSpaceAdminQuery.graphql';

export interface UseStaticContextHookResult {
	staticContext?: StaticContext;
	loading: boolean;
	error?: Error;
}

export interface UseStaticContextProps {
	moduleType: ForgeModules;
	spaceKey?: string;
}

export function useStaticContext({
	moduleType,
	spaceKey,
}: UseStaticContextProps): UseStaticContextHookResult {
	const { isLoggedIn, userId } = useSessionData();

	const isGlobalExtension = forgeGlobalExtensions.includes(moduleType);

	const {
		data: spaceAdminData,
		loading: spaceAdminLoading,
		error: spaceAdminError,
	} = useQuery(
		// eslint-disable-next-line graphql-relay-compat/no-import-graphql-operations -- Read https://go/connie-relay-migration-fyi
		DisplayConditionsSpaceAdminQuery,
		{
			variables: {
				spaceKey,
			},
			skip: !spaceKey || isGlobalExtension,
		},
	);

	const {
		data: operationData,
		loading: operationLoading,
		error: operationError,
	} = useQuery(
		// eslint-disable-next-line graphql-relay-compat/no-import-graphql-operations -- Read https://go/connie-relay-migration-fyi
		OperationsQuery,
		{
			skip: !userId,
		},
	);
	let canViewUserProfiles = false;

	if (operationData) {
		canViewUserProfiles = operationData?.siteOperations?.userProfile?.includes('read');
	}
	const {
		data: privilegeData,
		loading: privilegeLoading,
		error: privilegeError,
	} = useQuery(
		// eslint-disable-next-line graphql-relay-compat/no-import-graphql-operations -- Read https://go/connie-relay-migration-fyi
		UserPrivilegeQuery,
		{
			skip: operationLoading || !!operationError || !canViewUserProfiles,
		},
	);
	const isSpaceAdmin = spaceAdminData?.space?.currentUser?.isAdmin;
	const isSiteAdmin = getIsSiteAdminFromQuery(privilegeData);
	const isAdmin = isSiteAdmin || getIsProductAdminFromQuery(privilegeData);
	const staticContext = useMemo(
		() => ({
			isSpaceAdmin,
			isLoggedIn,
			isAdmin,
			isSiteAdmin,
		}),
		[isSpaceAdmin, isLoggedIn, isAdmin, isSiteAdmin],
	);

	if (spaceAdminLoading || spaceAdminError) {
		return { loading: spaceAdminLoading, error: spaceAdminError };
	}

	if (operationLoading || operationError) {
		return { loading: operationLoading, error: operationError };
	}

	if (privilegeLoading || privilegeError) {
		return { loading: privilegeLoading, error: privilegeError };
	}

	return {
		staticContext,
		loading: false,
	};
}
