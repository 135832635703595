import { useEffect, useMemo } from 'react';
import { useIntl } from 'react-intl-next';

import {
	useExtensionList as useExtensionListExternal,
	type ForgeUIExtensionQueryOptions,
} from '@atlassian/forge-ui/provider';

import { markErrorAsHandled } from '@confluence/graphql';
import { useSessionData } from '@confluence/session-data';
import { useSpaceDetail } from '@confluence/space-utils';
import { usePageSpaceKey } from '@confluence/page-context';
import { useIsExternalCollaborator } from '@confluence/external-collab-ui/entry-points/useIsExternalCollaborator';
import { isUnauthorizedError, isTooManyRequestsError } from '@confluence/error-boundary';

import { useExtensionsFilteredByDisplayConditions } from '../display-conditions';
import type { ForgeModules } from '../ForgeModules';
import {
	FORGE_MODULE_BYLINE,
	FORGE_MODULE_CONTENT_ACTION,
	FORGE_MODULE_CONTEXT_MENU,
	FORGE_MODULE_CUSTOM_CONTENT,
	FORGE_MODULE_SPACE_SETTINGS,
	FORGE_MODULE_SPACE_PAGE,
} from '../ForgeModuleType';
import { isBlockedByAppAccessRule } from '../utils';

import { getUseExtensionListOptions } from './getUseExtensionListOptions';

const supportedAccessNarrowingModules = new Set<ForgeModules>([
	FORGE_MODULE_BYLINE,
	FORGE_MODULE_CONTENT_ACTION,
	FORGE_MODULE_CONTEXT_MENU,
	FORGE_MODULE_CUSTOM_CONTENT,
	FORGE_MODULE_SPACE_PAGE,
	FORGE_MODULE_SPACE_SETTINGS,
]);

export type UseExtensionListProps = {
	moduleType: ForgeModules;
	queryOptions?: ForgeUIExtensionQueryOptions;
	expandAppOwner?: boolean;
	returnBlockedExtensions?: boolean;
	skipQuery?: boolean;
};

export const supportsAccessNarrowing = (moduleType: ForgeModules): boolean => {
	return supportedAccessNarrowingModules.has(moduleType);
};

export const useExtensionList = ({
	moduleType,
	queryOptions,
	expandAppOwner = false,
	returnBlockedExtensions = false,
	skipQuery = false,
}: UseExtensionListProps) => {
	const { locale } = useIntl();
	const { featureFlagClient, userId, cloudId, activationId } = useSessionData();
	const [spaceKey] = usePageSpaceKey();
	const {
		data: spaceData,
		loading: spaceLoading,
		error: spaceError,
	} = useSpaceDetail(spaceKey, featureFlagClient);
	const { isExternalCollaborator } = useIsExternalCollaborator();

	const isAnonymous = !userId;
	const dataClassificationTags = spaceData?.dataClassificationTags;
	const hasAccessNarrowingSupport = supportsAccessNarrowing(moduleType);

	const {
		extensions: extsData,
		loading: extsLoading,
		error: extsError,
	} = useExtensionListExternal(
		getUseExtensionListOptions({
			cloudId,
			activationId,
			locale,
			dataClassificationTags,
			hasAccessNarrowingSupport,
			expandAppOwner,
			moduleType,
			queryOptions,
			skip:
				skipQuery || isAnonymous || isExternalCollaborator || spaceLoading || Boolean(spaceError),
			skipFilter: false,
		}),
	);

	// Filter out extensions blocked by App Access Rule if necessary
	const extsDataFilteredByAppAccessRule = useMemo(
		() =>
			extsData && !returnBlockedExtensions
				? extsData.filter((ext) => !isBlockedByAppAccessRule(ext))
				: extsData,
		[extsData, returnBlockedExtensions],
	);

	useEffect(() => {
		// suppress 403 errors as we can't do much with them
		if (extsError && isUnauthorizedError(extsError)) {
			markErrorAsHandled(extsError);
		}

		// suppress 429 errors for offending users
		if (isTooManyRequestsError(extsError)) {
			markErrorAsHandled(extsError);
		}

		if (spaceError) {
			markErrorAsHandled(spaceError);
		}
	}, [extsError, spaceError]);

	const { extensions, loading, error } = useExtensionsFilteredByDisplayConditions(
		moduleType,
		extsDataFilteredByAppAccessRule,
		extsLoading || spaceLoading,
		extsError || spaceError,
	);

	return {
		extensions,
		loading,
		error,
	};
};
