import { useState, useEffect } from 'react';
import countBy from 'lodash/countBy';
import uniqBy from 'lodash/uniqBy';

import { useAnalyticsEvents } from '@atlaskit/analytics-next';

import { ClientDataProvider, doesMeetConditions } from '@atlassian/forge-conditions';
import type { StaticContext, PropertyValueRequest } from '@atlassian/forge-conditions';

import { markErrorAsHandled } from '@confluence/graphql/src';
import { usePageContentId, usePageSpaceKey } from '@confluence/page-context';
import { useRenderServerPlaceholder } from '@confluence/ssr-utilities';
import { fg } from '@confluence/feature-gating';

import {
	FORGE_MODULE_BYLINE,
	FORGE_MODULE_CONTENT_ACTION,
	FORGE_MODULE_CONTEXT_MENU,
	FORGE_MODULE_GLOBAL_PAGE,
	FORGE_MODULE_GLOBAL_SETTINGS,
	FORGE_MODULE_SPACE_SETTINGS,
} from '../ForgeModuleType';
import type { Extension } from '../types';
import type { ForgeModules } from '../ForgeModules';

import type { FetchContext } from './fetchPropertyData';
import { fetchPropertyData } from './fetchPropertyData';
import { useStaticContext } from './useStaticContext';

export type ExtensionsState = {
	extensions: Extension[];
	loading: boolean;
	error?: Error;
};

const noExtensions = [];

const extendStaticContext = (baseContext: StaticContext, ext: Extension) => {
	const { hasAccess: hasAppAccess = false } = ext.userAccess || {};
	const { active: appIsLicensed = false, capabilitySet } = ext.license || {};
	const appEdition = mapCapabilitySetToEdition(capabilitySet);

	const extendedContext: StaticContext = {
		...baseContext,
		hasAppAccess,
		appIsLicensed,
		...(appEdition && { appEdition }),
	};

	return extendedContext;
};

const mapCapabilitySetToEdition = (capabilitySet: string | undefined) => {
	switch (capabilitySet) {
		case 'capabilityStandard':
			return 'standard';
		case 'capabilityAdvanced':
			return 'advanced';
		case 'capabilityFree':
			return 'free';
		default:
			return capabilitySet;
	}
};

const initiateConditionsCheck = (
	extensions: Extension[],
	dataProvider: ClientDataProvider,
	staticContext: StaticContext,
): Promise<boolean>[] => {
	// TODO(rtoropov): Add displayConditions to Extension type to avoid conversion to 'any'.
	return extensions
		.map((ext) => {
			const conditions = (ext.properties as any).displayConditions;

			if (!conditions) {
				return true;
			}

			const contextToUse = fg('ecosystem_enable_app_display_conditions')
				? extendStaticContext(staticContext, ext)
				: staticContext;

			return doesMeetConditions(conditions, contextToUse, dataProvider);
		})
		.map((boolOrPromise) =>
			typeof boolOrPromise === 'boolean' ? Promise.resolve(boolOrPromise) : boolOrPromise,
		);
};

const maybeFetchPropertyData = (
	dataProvider: ClientDataProvider,
	fetchContext: FetchContext,
	fetchErrorRef: { error? },
) => {
	if (dataProvider.propertyValueRequests.length) {
		fetchPropertyData(dataProvider.propertyValueRequests, fetchContext)
			.then((response) => dataProvider.resolve({ propertyValues: response }))
			.catch((error) => {
				fetchErrorRef.error = error;
				markErrorAsHandled(error);
				return dataProvider.reject(error);
			});
	}
};

const hasConditions = (extension: Extension) => (extension.properties as any).displayConditions;

type AnalyticsEventData = {
	contentId: string;
	spaceKey: string;
	moduleType: ForgeModules;
	allExtensions: Extension[];
	displayedExtensions: Extension[];
	propertyRequests?: PropertyValueRequest[];
	fetchError?: Error;
	conditionsError?: Error;
};

const getAnalyticsEvent = ({
	contentId,
	spaceKey,
	moduleType,
	allExtensions,
	displayedExtensions,
	propertyRequests,
	fetchError,
	conditionsError,
}: AnalyticsEventData) => {
	const requestCountByEntityType = countBy(
		// Remove requests for the same property key and same entity type (could be multiple because of `objectName` attr).
		uniqBy(propertyRequests, (req) => req.entity + req.propertyKey),
		(request) => request.entity,
	);
	return {
		type: 'sendOperationalEvent',
		data: {
			containerType: 'space',
			containerId: spaceKey,
			objectType: 'page',
			objectId: contentId,
			source: moduleType,
			action: 'displayConditionsProcessed',
			actionSubject: 'forgeUIExtensions',
			actionSubjectId: moduleType,
			attributes: {
				totalExtensions: allExtensions.length,
				totalWithConditions: allExtensions.filter(hasConditions).length,
				totalDisplayed: displayedExtensions.length,
				fetchedPropertyCounts: requestCountByEntityType,
				fetchFailed: Boolean(fetchError),
				conditionsFailed: Boolean(conditionsError),
			},
		},
	};
};

const handleFulfilled = (
	results: PromiseSettledResult<boolean>[],
	extensions: Extension[],
): Extension[] =>
	extensions.filter(
		(_, i) =>
			results[i].status === 'fulfilled' && (results[i] as PromiseFulfilledResult<boolean>).value,
	);

const handleRejected = (results: PromiseSettledResult<boolean>[]): Error | undefined => {
	const reason = (results.find((result) => result.status === 'rejected') as PromiseRejectedResult)
		?.reason;
	return reason ? new Error(reason.message || reason) : undefined;
};

// If the module supports and uses useExtensionsFilteredByDisplayConditions hook, the module name should be added to the array below
const supportedDisplayConditionsModules = new Set<ForgeModules>([
	FORGE_MODULE_BYLINE,
	FORGE_MODULE_CONTENT_ACTION,
	FORGE_MODULE_CONTEXT_MENU,
	FORGE_MODULE_GLOBAL_PAGE,
	FORGE_MODULE_GLOBAL_SETTINGS,
	FORGE_MODULE_SPACE_SETTINGS,
]);

export const filterExtensionsByDisplayConditions = async (
	extensions: Extension[],
	staticContext: StaticContext,
	fetchContext: any,
) => {
	const dataProvider = new ClientDataProvider();

	// This function has side effects to set dataProvider.propertyValueRequests used in maybeFetchPropertyData
	const resultsPromises: Promise<boolean>[] = initiateConditionsCheck(
		extensions,
		dataProvider,
		staticContext,
	);

	const fetchErrorRef: { error? } = {};
	// This function has side effects that if it is not called before allSettled, the promise below will never resolve.
	maybeFetchPropertyData(dataProvider, fetchContext, fetchErrorRef);

	const results = await Promise.allSettled(resultsPromises);
	const extsToDisplay = handleFulfilled(results, extensions);
	const conditionsError = handleRejected(results);
	return {
		dataProvider,
		extsToDisplay,
		conditionsError,
		fetchError: fetchErrorRef.error,
	};
};

export function useExtensionsFilteredByDisplayConditions(
	moduleType: ForgeModules,
	allExtensions: Extension[],
	extensionsLoading: boolean,
	extensionsError?: Error,
): ExtensionsState {
	const extensions = !allExtensions.length ? noExtensions : allExtensions;

	const { createAnalyticsEvent } = useAnalyticsEvents();
	const [contentId] = usePageContentId();
	const [spaceKey] = usePageSpaceKey();
	const {
		staticContext,
		loading: staticContextLoading,
		error: staticContextError,
	} = useStaticContext({ moduleType, spaceKey });

	const renderServerPlaceholder = useRenderServerPlaceholder();

	const [extensionsState, setExtensionsState] = useState<ExtensionsState>(() => {
		// Only use the cached data once on server-side and when hydrate
		const serverOrSSRRendered = renderServerPlaceholder || window.__SSR_RENDERED__;
		const hasContext = !staticContextLoading && staticContext;
		if (serverOrSSRRendered && hasContext && window.__SSR_FORGE_EXTENSIONS__) {
			const cached = window.__SSR_FORGE_EXTENSIONS__[moduleType];
			if (cached) {
				if (window.__SSR_RENDERED__) {
					// Ensure on client-side the cached data only used once when hydrate.
					delete window.__SSR_FORGE_EXTENSIONS__[moduleType];
				}
				return {
					...cached,
					loading: false,
				};
			}
		}

		return {
			extensions: noExtensions,
			loading: true,
			error: undefined,
		};
	});

	useEffect(() => {
		if (!supportedDisplayConditionsModules.has(moduleType)) {
			setExtensionsState({
				extensions,
				loading: extensionsLoading,
				error: extensionsError,
			});
			return;
		}

		if (extensionsError || staticContextError) {
			setExtensionsState({
				extensions: noExtensions,
				loading: false,
				error: extensionsError || staticContextError,
			});
			return;
		}

		if (staticContextLoading || extensionsLoading) {
			return;
		}

		const fetchContext = { contentId, spaceKey };
		void filterExtensionsByDisplayConditions(extensions, staticContext!, fetchContext).then(
			({ dataProvider, extsToDisplay, conditionsError, fetchError }) => {
				setExtensionsState({
					extensions: extsToDisplay,
					loading: false,
					error: conditionsError,
				});
				if (extensions.some(hasConditions)) {
					createAnalyticsEvent(
						getAnalyticsEvent({
							contentId: contentId as string,
							spaceKey: spaceKey as string,
							moduleType,
							allExtensions: extensions,
							displayedExtensions: extsToDisplay,
							propertyRequests: dataProvider.propertyValueRequests,
							fetchError,
							conditionsError,
						}),
					).fire();
				}
			},
		);
	}, [
		extensions,
		staticContext,
		extensionsLoading,
		staticContextLoading,
		extensionsError,
		staticContextError,
		contentId,
		spaceKey,
		moduleType,
		createAnalyticsEvent,
	]);

	return extensionsState;
}
